<template>
	<div
		data-layout="activity"
		:data-error="getErrorIsVisible"
	>
		<header>
			<app-logo
				size="sm"
				:linkToHome="false"
				:linkToLesson="true"
			/>
			<breadcrumbs
				v-if="getShowBreadcrumbs"
			/>
		</header>

		<transition
			name="fade"
		>
			<loading
				v-show="getLoadingIsVisible"
			/>
		</transition>

		<transition name="fade" mode="out-in">
			<component
				:is="getRouteComponent"
				:key="$route.fullPath"
			/>
		</transition>

		<notification />
		<lesson-nav />
		<nav-button />
		<modal />
	</div>
</template>

<script>

	import AppLogo       from '@/components/ui/header/AppLogo';
	import Breadcrumbs   from '@/components/ui/Breadcrumbs';
	import Loading       from '@/components/ui/Loading';
	import Notification  from '@/components/ui/Notification';
	import FourOhFour    from '@/pages/404';
	import LessonNav     from '@/components/lesson/lessonNav/LessonNav';
	import NavButton     from '@/components/lesson/lessonNav/NavButton';
	import Modal         from '@/components/ui/modal/Modal';
	import routeParams   from '@/mixins/routeParams';

	export default {
		components: {
			AppLogo,
			Breadcrumbs,
			Loading,
			FourOhFour,
			Notification,
			LessonNav,
			NavButton,
			Modal
		},
		mixins: [routeParams],
		computed: {
			getErrorIsVisible () {
				return this.$store.getters['ui/getErrorIsVisible'];
			},
			getLoadingIsVisible () {
				return this.$store.getters['ui/getLoadingIsVisible'];
			},
			getShowBreadcrumbs () {
				if (this.getContext !== 'admin' &&
						this.getContext !== 'teaching') {
					return false;
				}
				return true;
			},
			getRouteComponent () {
				if (this.$store.getters['ui/getErrorIsVisible']) {
					return 'FourOhFour';
				}
				return 'router-view';
			}
		},
		methods: {}
	};

</script>

<style lang="scss" scoped>
	[data-layout='activity'] {
		position:relative;
		display:flex;
		flex-direction:column;
		min-height:100vh;
		background-color:$c-white;
		&:before {
			content:'';
			position:absolute;
			z-index:-1;
			top:12px;
			right:12px;
			bottom:12px;
			left:12px;
			display:block;
			border:4px solid $c-brand-blue;
			border-radius:4px;
			pointer-events:none;
		}
		&:after {
			content:'';
			position:absolute;
			z-index:-1;
			top:19px;
			right:19px;
			bottom:19px;
			left:19px;
			display:block;
			border:2px solid $c-brand-red;
			pointer-events:none;
		}
		&[data-error] {
			@include grid-container;
			padding-top:rem(64);
		}
		header {
			position:fixed;
			top:rem(36);
			left:rem(36);
			right:auto;
			display:flex;
			align-items:center;
			[data-component='app-logo'] {
				margin-right:rem(48);
				flex-shrink:0;
			}
			[data-component='breadcrumbs'] {
				display:none;
				@include mq('sm') {
					display:block;
				}
				margin:auto rem(64) auto 0;
			}
		}
		[data-component='loading'] {
			top:0; right:0; bottom:0; left:0;
			width:100vw; height:100vh;
		}
		[data-component='nav-button'] {
			position:fixed;
			top:rem(28);
			right:rem(28);
		}
		[data-component='btn'] {
			position:fixed;
			bottom:rem(24);
			right:rem(24);
		}
	}
</style>
